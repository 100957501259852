function shouldUpdateCampaignDetails(newCampaignDetails, currentTimeInMs, campaignDetailsLifetimeInDays) {

  if (!localStorage.hasOwnProperty('campaign_details')) {
    // Always update if we have no existing campaign details.
    return true;
  }

  if (!newCampaignDetails['referrer'] || (newCampaignDetails['utmCampaign'] && newCampaignDetails['utmCampaign'].includes('brand'))) {
    // This is a direct or brand visit; update if the existing campaign details have expired.
    const lastUpdateTimeInMs = parseInt(localStorage['campaign_details_updated_at']);
    const timeSinceLastUpdateInDays = (currentTimeInMs - lastUpdateTimeInMs)/24/60/60/1000;
    return timeSinceLastUpdateInDays > campaignDetailsLifetimeInDays;
  }

  // This is a non-direct visit; update if the new campaign details are different.
  return localStorage['campaign_details'] !== JSON.stringify(newCampaignDetails);
};

// Set campaign details
if (!sessionStorage.hasOwnProperty('campaign_details_set')) {

  const campaignDetailsLifetimeInDays = 180;
  const currentTimeInMs = Date.now();
  const urlParams = new URLSearchParams(document.location.search);

  const newCampaignDetails = {
    'landingPage': window.location.href || '',
    'referrer': document.referrer || '',
    'utmCampaign': urlParams.get('utm_campaign') || '',
    'utmContent': urlParams.get('utm_content') || '',
    'utmMedium': urlParams.get('utm_medium') || '',
    'utmSource': urlParams.get('utm_source') || '',
    'utmTerm': urlParams.get('utm_term') || '',
  };

  if (shouldUpdateCampaignDetails(newCampaignDetails, currentTimeInMs, campaignDetailsLifetimeInDays)) {
    localStorage['campaign_details'] = JSON.stringify(newCampaignDetails);
    localStorage['campaign_details_updated_at'] = currentTimeInMs;
  }

  // Set a flag in the session storage so we can avoid calling this
  // routine more than once per session.
  sessionStorage['campaign_details_set'] = true;
}
